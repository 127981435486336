/*!  #sidebar
================================================ */
#sidebar { 
  .bnr { 
    margin-bottom: 30px;
  }
  .sideArchive{ 
	margin: 0 0 26px;
	padding: 0 15px;
	@media screen and (min-width: 600px) { 
		margin: 0 0 26px;
		padding: 0;
	}
    .tit { 
      font-size: 1.6rem;
      line-height: 1;
      border-bottom: 1px solid #dcdcdc;
      margin-bottom: 16px;
      padding: 3px 0 14px 34px;
      background: url(../img/common/sidebar/ico_calendar01.png) 0 0 no-repeat;
		&.area { 
			background: url(../img/common/sidebar/ico_area01.png) 0 0 no-repeat;
		}
		@media screen and (min-width: 600px) { 
			font-size: 2rem;
		}
    }
	ul{ 
		padding: 0 0 0 20px;

		li{ 
			margin: 0 0 15px;
			@media screen and (min-width: 600px) { 
				margin: 0 0 9px;
			}
			a{ 
				display: block;
				position: relative;
				color: #fd6978;
				font-size: 1.4rem;
				&:before{ 
					content: "";
					position: relative;
					display: inline-block;
					width: 0;
					height: 0;
					margin: 0 10px 2px 0;
					border-style: solid;
					border-width: 4px 0 4px 5px;
					border-color: transparent transparent transparent #f36876;
				}
			}
		}
	}
  }
  .sideCalendar { 
	  padding: 0 15px;
	  @media screen and (min-width: 600px) { 
		  padding: 0;
	  }
    .year { 
      font-size: 2rem;
      line-height: 1;
      border-bottom: 1px solid #dcdcdc;
      margin-bottom: 20px;
      padding: 3px 0 14px 34px;
      background: url(../img/common/sidebar/ico_calendar01.png) 0 0 no-repeat;
    }
    .list { 
      overflow: hidden;
      margin-bottom: 28px;
      .num { 
		width: 31.2%;
		  margin-right: 8px;
		  @media screen and (min-width: 600px) { 
			width: 98px;
			margin-right: 3px;
		  }
        margin-bottom: 3px;
        float: left;
        &:nth-of-type(3n) { 
          margin-right: 0;
        }
        a { 
          display: block;
          line-height: 40px;
          background: #ffe7f0;
          color: #f5568b;
          font-size: 1.6rem;
          text-align: center;
        }
      }
    }
  }
  .sideRecommend .tit { 
    font-size: 2rem;
    line-height: 1;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 20px;
    padding: 3px 0 14px 34px;
    background: url(../img/common/ico_recom01.png) 0 0 no-repeat;
    background-size: 27px auto;
	  &.sightseen { 
		  background: url(../img/common/sidebar/ico_sightseen01.png) left top 4px no-repeat;
    	background-size: 23px auto;
	  }
	  &.gourmet{ 
		  background: url(../img/common/sidebar/ico_gourmet01.png) left top 4px no-repeat;
    	background-size: 23px auto;
	  }
  }
  .popularList { 
    margin-bottom: 30px;
    li { 
      border-bottom: 1px solid #dcdcdc;
      a { 
        display: block;
        padding-bottom: 15px;
        position: relative;
        min-height: 97px;
        padding-left: 135px;
        .pic { 
          position: absolute;
          top: 0;
          left: 0;
          width: 120px;
        }
        .title { 
          font-size: 1.6rem;
        }
        .category { 
          display: inline-block;
          padding: 2px 12px;
          color: #9e4fbf;
          border-width: 1px;
          border-style: solid;
          border-color: #9e4fbf;
          margin-bottom: 10px;
          font-size: 1.2rem;
		  &.gourmet{ 
           color: #2198c4;
			border-color: #2198c4;
		  }
        }
      }
      + li a { 
        padding-top: 15px;
        min-height: 80px;
        .pic { 
          top: 15px;
        }
      }
    }
  }
}