@charset "UTF-8";
/*=============================================================
 02_base
=============================================================*/
/*!  02_base
================================================ */
html {  overflow: auto; }

body {  min-width: 320px; color: #333; padding-top: 50px; font-size: 1.3rem; font-weight: 300; line-height: 1.53846; font-family: "Noto Sans Japanese", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif; letter-spacing: .1em; overflow: hidden; }

@media screen and (min-width: 600px) {  body {  min-width: 1180px; padding-top: 75px; } }

div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video, input, select, textarea {  font-size: inherit; font-weight: inherit; font-family: inherit; }

.mincho {  font-family: "Sawarabi Mincho", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif; font-weight: 400; }

a {  color: #333; text-decoration: none; }

.alpha {  display: block; }

@media all and (min-width: 600px) {  .alpha {  transition: all 0.4s ease-in-out; }
  .alpha:hover {  opacity: .8; text-decoration: none; } }

img {  width: 100%; height: auto; }

.cf:after {  display: block; visibility: hidden; clear: both; content: ""; }

.viewPc {  display: none; }

@media all and (min-width: 600px) {  .viewSp {  display: none; }
  .viewPc {  display: block; } }

/* layout
-------------------------------------------------*/
#wrapper {  position: relative; }

@media all and (min-width: 600px) {  #container {  position: relative; z-index: 10; width: 1180px; margin: -504px auto 0; padding: 45px 0 90px; background: #fff; }
  #container:before {  position: absolute; top: 0; left: -80px; z-index: -1; width: 1340px; height: 100%; background: #fff; content: ""; }
  #home #container {  position: static; width: auto; margin: 0; padding: 0; background: transparent; }
  #home #container:before {  display: none; } }

@media all and (min-width: 600px) {  #contents {  float: left; width: 860px; } }

@media all and (min-width: 600px) {  #sidebar {  float: right; width: 300px; } }

/*=============================================================
 03_layout
=============================================================*/
/* #gHeader
================================================ */
#gHeader {  position: fixed; top: 0; left: 0; width: 100%; min-width: 320px; background: #fff; z-index: 2000; }

@media screen and (min-width: 600px) {  #gHeader {  min-width: 1180px; }
  #gHeader .inner {  width: 1180px; margin: 0 auto; }
  #gHeader .btnSearch {  position: absolute; top: 0; right: 0; width: 75px; height: 75px; background: #fd6978 url(../img/common/ico_search01.png) no-repeat 50% 50%; background-size: 22px; cursor: pointer; } }

/* #hdInfo
-------------------------------------- */
#hdInfo {  position: relative; padding: 5px 0; height: 40px; background: #fff; }

#hdInfo .logo {  width: 133px; margin: 4px auto 0; }

#hdInfo .logo img {  display: block; }

#hdInfo .btnMenu {  position: absolute; top: 0; right: 0; width: 50px; height: 50px; background: #fd6978; cursor: pointer; }

#hdInfo .btnMenu .line {  position: absolute; top: 13px; left: 16px; width: 18px; height: 2px; background: #fff; transition: all 0.4s ease-in-out; }

#hdInfo .btnMenu .line:nth-child(1) {  top: 13px; }

#hdInfo .btnMenu .line:nth-child(2) {  top: 19px; }

#hdInfo .btnMenu .line:nth-child(3) {  top: 25px; }

#hdInfo .btnMenu .txt {  position: absolute; bottom: 6px; left: 0; width: 100%; color: #fff; font-size: 1.1rem; letter-spacing: .14em; text-align: center; line-height: 1.09091; }

#hdInfo .btnMenu.is_open .line:nth-child(1) {  top: 19px; transform: rotate(45deg); }

#hdInfo .btnMenu.is_open .line:nth-child(2) {  opacity: 0; }

#hdInfo .btnMenu.is_open .line:nth-child(3) {  top: 19px; transform: rotate(-45deg); }

@media screen and (min-width: 600px) {  #hdInfo {  float: left; height: auto; padding: 8px 0 11px; }
  #hdInfo .logo {  width: 173px; height: 47px; margin: 6px 0 0; } }

/* #hdMenu
-------------------------------------- */
#hdMenu {  display: none; position: absolute; top: 50px; left: 0; width: 100%; min-width: 320px; padding-bottom: 28px; background: #f2f6f8; box-sizing: border-box; overflow-y: scroll; z-index: 2000; }

@media screen and (min-width: 600px) {  #hdMenu {  display: block; position: static; width: auto; float: right; padding-right: 160px; padding-bottom: 0; background: transparent; overflow-y: visible; } }

@media screen and (min-width: 1510px) {  #hdMenu {  padding-right: 0; } }

/* #hdSearch
-------------------------------------- */
#hdSearch {  padding: 20px 15px; }

#hdSearch .inner {  position: relative; padding-right: 50px; }

#hdSearch .inner .txt {  display: block; width: 100%; height: 45px; appearance: none; margin: 0; padding: 0 13px; border: 1px solid #fd6978; border-radius: 4px; outline: none; background: #fff; box-shadow: none; box-sizing: border-box; font-size: 1.2rem; line-height: 1.5; }

#hdSearch .inner .btn {  display: block; position: absolute; top: 0; right: 0; appearance: none; width: 45px; height: 45px; margin: 0; padding: 0; border: 0; outline: 0; box-shadow: none; }

@media screen and (max-width: 599px) {  #hdSearch .btn {  background: #fd6978 url(../img/common/ico_search01.png) no-repeat 50% 50%; background-size: 25px; text-indent: 100%; white-space: nowrap; overflow: hidden; } }

@media screen and (min-width: 600px) {  #hdSearch {  display: none; position: absolute; top: 75px; left: 0; width: 100%; min-width: 1180px; padding: 28px 0 27px; background: #f2f6f8; }
  #hdSearch .inner {  width: 450px; margin: 0 auto; padding-right: 160px; }
  #hdSearch .inner .txt {  height: 60px; padding: 0 15px; border-radius: 6px; font-size: 1.4rem; }
  #hdSearch .inner .btn {  width: 150px; height: 60px; background: #fd6978; box-sizing: border-box; color: #fff; font-size: 1.6rem; font-weight: 500; text-align: center; line-height: 1.5; } }

/* #gNav
================================================ */
#gNav {  border-top: 1px solid #dcdcdc; }

#gNav .list {  margin-bottom: 30px; }

#gNav .list .item {  border-bottom: 1px solid #dcdcdc; }

#gNav .list .item > a {  position: relative; display: block; padding: 12px 15px 11px 35px; font-size: 1.4rem; font-weight: 500; line-height: 1.5; }

#gNav .list .item > a::before {  position: absolute; top: 50%; left: 15px; width: 0; height: 0; content: ''; margin-top: -4px; border-style: solid; border-width: 4px 0 4px 4px; border-color: transparent transparent transparent #333; }

#gNav .list .item > a span {  color: #fd6978; }

#gNav .list .item .parent {  position: relative; padding: 12px 15px 11px 37px; font-size: 1.5rem; font-weight: 500; line-height: 1.4; cursor: pointer; }

#gNav .list .item .parent span {  color: #fd6978; }

#gNav .list .item .parent .ico {  position: absolute; top: 50%; left: 13px; display: block; width: 17px; height: 17px; margin-top: -7px; }

#gNav .list .item .parent::after {  position: absolute; top: 50%; right: 15px; width: 0; height: 0; content: ''; margin-top: -2px; border-style: solid; border-width: 4px 4px 0 4px; border-color: #fd6978 transparent transparent; }

#gNav .list .item .content {  display: none; }

#gNav .list .item .cat {  padding: 0 11px 7px; overflow: hidden; }

#gNav .list .item .cat .child {  float: left; width: 50%; margin-bottom: 8px; padding: 0 4px; box-sizing: border-box; }

#gNav .list .item .cat .child a {  display: block; height: 61px; padding: 12px 0 0; border: 1px solid; background: #fff; text-align: center; }

#gNav .list .item .cat .child a .ico {  display: block; margin-bottom: 1px; }

#gNav .list .item .cat .child a .ico img {  display: block; width: 34px; margin: 0 auto; }

#gNav .list .item .cat .child a .txt {  font-size: 1.6rem; font-weight: 500; }

#gNav .list .item .cat .child.sightseen a {  color: #9e4fbf; border-color: #9e4fbf; }

#gNav .list .item .cat .child.gourmet a {  color: #2198c4; border-color: #2198c4; }

#gNav .list .item .cat .child.event a {  color: #71c72b; border-color: #71c72b; }

#gNav .list .item .cat .child.experience a {  color: #e6b33a; border-color: #e6b33a; }

#gNav .list .item .cat .child.souvenir a {  color: #ef7e3a; border-color: #ef7e3a; }

#gNav .list .item .cat .child.hotel a {  color: #e54c80; border-color: #e54c80; }

#gNav .list .item .cat .child.life a {  color: #925848; border-color: #925848; }

#gNav .list .item .area {  padding: 18px 15px 4px; background: #fd6978; overflow: hidden; }

#gNav .list .item .area .child {  float: left; width: 50%; margin-bottom: 12px; }

#gNav .list .item .area .child a {  position: relative; display: block; padding: 0 10px; color: #fff; font-size: 1.2rem; line-height: 1.5; }

#gNav .list .item .area .child a::before {  position: absolute; top: 50%; left: 0; width: 0; height: 0; content: ''; margin-top: -4px; border-style: solid; border-width: 4px 0 4px 4px; border-color: transparent transparent transparent #fff; }

#gNav .contact {  width: 230px; margin: 0 auto 25px; }

#gNav .contact a {  display: block; padding: 10px 15px 10px 82px; background: #4d4d4d url(../img/common/ico_contact01.png) no-repeat 54px 50%; background-size: 18px auto; color: #fff; font-weight: 500; }

#gNav .sns {  margin-bottom: 25px; text-align: center; letter-spacing: -.5em; }

#gNav .sns .item {  display: inline-block; width: 30px; margin: 0 6px; letter-spacing: 0; }

#gNav .lang {  width: 180px; margin: 0 auto; overflow: hidden; }

#gNav .lang .item {  float: left; width: 90px; }

#gNav .lang .item a {  display: block; padding: 12px 0; background: #ffe7f0; color: #fd6978; font-size: 1.4rem; font-weight: 500; text-align: center; line-height: 1.5; }

#gNav .lang .item.current a {  background: #fd6978; color: #fff; }

@media screen and (min-width: 600px) {  #gNav {  border-top: 0; }
  #gNav .list {  float: left; margin-bottom: 0; margin-right: 12px; padding-top: 8px; }
  #gNav .list .item {  float: left; border-bottom: 0; }
  #gNav .list .item:nth-child(1) {  width: 183px; }
  #gNav .list .item:nth-child(2) {  width: 168px; }
  #gNav .list .item:nth-child(3) {  width: 160px; }
  #gNav .list .item > a {  padding: 18px 0 17px 45px; font-size: 1.5rem; line-height: 1.4; }
  #gNav .list .item > a::before {  display: none; }
  #gNav .list .item > a .ico {  position: absolute; top: 50%; left: 15px; display: block; width: 20px; margin-top: -10px; }
  #gNav .list .item .parent {  padding: 18px 0 17px 40px; }
  #gNav .list .item .parent .ico {  left: 15px; width: 20px; height: 20px; margin-top: -10px; }
  #gNav .list .item .parent::after {  position: absolute; top: auto; right: 50%; bottom: 5px; margin-top: 0; margin-right: -15px; border-color: #dcdcdc transparent transparent; }
  #gNav .list .item .content {  position: absolute; top: 75px; left: 0; width: 100%; background: #f2f6f8; z-index: 2000; }
  #gNav .list .item .cat {  width: 1180px; margin: 0 auto; padding: 0; border-left: 1px solid #dcdcdc; box-sizing: border-box; }
  #gNav .list .item .cat.temporary {  width: 370px; }
  #gNav .list .item .cat.temporary .child {  width: 50%; }
  #gNav .list .item .cat .child {  float: left; width: 14.28571%; margin-bottom: 0; padding: 0; }
  #gNav .list .item .cat .child a {  display: block; height: 95px; padding: 30px 0 0; background: transparent; }
  #gNav .list .item .cat .child a .ico {  margin-bottom: 5px; }
  #gNav .list .item .cat .child a .ico img {  width: 42px; }
  #gNav .list .item .cat .child a .txt {  position: relative; font-size: 1.7rem; line-height: 1.41176; }
  #gNav .list .item .cat .child a .txt::before, #gNav .list .item .cat .child a .txt::after {  position: absolute; top: 63px; width: 2px; height: 24px; content: ''; opacity: 0; }
  #gNav .list .item .cat .child a .txt::before {  left: -14px; transform: rotate(-30deg); }
  #gNav .list .item .cat .child a .txt::after {  right: -14px; transform: rotate(30deg); }
  #gNav .list .item .cat .child.sightseen a {  border: 1px solid #dcdcdc; border-width: 0 1px; }
  #gNav .list .item .cat .child.gourmet a, #gNav .list .item .cat .child.event a, #gNav .list .item .cat .child.experience a, #gNav .list .item .cat .child.souvenir a, #gNav .list .item .cat .child.hotel a, #gNav .list .item .cat .child.life a {  border: 1px solid #dcdcdc; border-width: 0 1px 0 0; }
  #gNav .list .item .cat .child.sightseen a {  border: 1px solid #dcdcdc; border-width: 0 1px; color: #9e4fbf; }
  #gNav .list .item .cat .child.sightseen a .txt::before, #gNav .list .item .cat .child.sightseen a .txt::after {  background: #9e4fbf; }
  #gNav .list .item .cat .child.gourmet a {  border: 1px solid #dcdcdc; border-width: 0 1px 0 0; color: #2198c4; }
  #gNav .list .item .cat .child.gourmet a .txt::before, #gNav .list .item .cat .child.gourmet a .txt::after {  background: #2198c4; }
  #gNav .list .item .cat .child.event a {  border: 1px solid #dcdcdc; border-width: 0 1px 0 0; color: #71c72b; }
  #gNav .list .item .cat .child.event a .txt::before, #gNav .list .item .cat .child.event a .txt::after {  background: #71c72b; }
  #gNav .list .item .cat .child.experience a {  border: 1px solid #dcdcdc; border-width: 0 1px 0 0; color: #e6b33a; }
  #gNav .list .item .cat .child.experience a .txt::before, #gNav .list .item .cat .child.experience a .txt::after {  background: #e6b33a; }
  #gNav .list .item .cat .child.souvenir a {  border: 1px solid #dcdcdc; border-width: 0 1px 0 0; color: #ef7e3a; }
  #gNav .list .item .cat .child.souvenir a .txt::before, #gNav .list .item .cat .child.souvenir a .txt::after {  background: #ef7e3a; }
  #gNav .list .item .cat .child.hotel a {  border: 1px solid #dcdcdc; border-width: 0 1px 0 0; color: #e54c80; }
  #gNav .list .item .cat .child.hotel a .txt::before, #gNav .list .item .cat .child.hotel a .txt::after {  background: #e54c80; }
  #gNav .list .item .cat .child.life a {  border: 1px solid #dcdcdc; border-width: 0 1px 0 0; color: #925848; }
  #gNav .list .item .cat .child.life a .txt::before, #gNav .list .item .cat .child.life a .txt::after {  background: #925848; }
  #gNav .list .item .cat .child a:hover .txt {  animation: gnav-hover-txt .45s ease-in; }
  #gNav .list .item .cat .child a:hover .txt::before, #gNav .list .item .cat .child a:hover .txt::after {  animation: gnav-hover-line .45s; top: 3px; opacity: 1; }
  #gNav .list .item .area {  width: 1180px; margin: 0 auto; padding: 27px 0 23px 613px; background: transparent; box-sizing: border-box; }
  #gNav .list .item .area .child {  width: 160px; }
  #gNav .list .item .area .child a {  padding: 0 12px; color: #333; }
  #gNav .list .item .area .child a::before {  margin-top: -4px; border-color: transparent transparent transparent #fd6978; }
  #gNav .list .item .area .child a:hover {  text-decoration: underline; }
  #gNav .sns {  float: left; margin-bottom: 0; padding-top: 25px; }
  #gNav .sns .item {  width: 25px; margin: 0 0 0 11px; }
  #gNav .lang {  position: absolute; top: 0; right: 75px; width: 75px; height: 75px; margin: 0; }
  #gNav .lang .item {  float: none; width: 75px; }
  #gNav .lang .item a {  height: 75px; padding: 25px 0 0; font-size: 1.6rem; box-sizing: border-box; } }

@keyframes gnav-hover-line {  0% {  top: 63px;
    opacity: 0; }
  50% {  top: 63px;
    opacity: 1; }
  99.9375% {  top: 1px; }
  100% {  top: 3px; } }

@keyframes gnav-hover-txt {  0% {  top: 0; }
  99.9375% {  top: -2px; }
  100% {  top: 0; } }

/*!  #mainVisual HOME
================================================ */
#mainVisual {  background: #ffe7f0; }

#mainVisual .tit {  line-height: 1.4; padding: 14px 0; background: no-repeat 50% 50%; background-size: cover; color: #fff; font-weight: 700; font-size: 1.5rem; text-align: center; letter-spacing: .14em; }

@media all and (min-width: 600px) {  #mainVisual {  position: relative; height: 659px; }
  #mainVisual .tit {  height: 203px; padding: 37px 0 0; font-size: 2.6rem; }
  #mainVisual .tit span {  position: relative; }
  #mainVisual .tit span:before, #mainVisual .tit span:after {  position: absolute; top: 2px; width: 4px; height: 35px; background: #fff; content: ""; }
  #mainVisual .tit span:before {  left: -28px; -webkit-transform: rotate(-30deg); transform: rotate(-30deg); }
  #mainVisual .tit span:after {  right: -28px; -webkit-transform: rotate(30deg); transform: rotate(30deg); }
  #mainVisual .bg {  position: absolute; bottom: 0; left: 0; width: 100%; height: 419px; background: url(../img/home/bg_mv02.png) no-repeat 50% 50%; background-size: cover; }
  #mainVisual .img {  position: absolute; bottom: 0; left: 50%; width: 1436px; margin-left: -730px; border: 12px solid #fff; border-width: 12px 12px 0; background: #fff; } }

/*!  #mainImg 下層
================================================ */
#mainImg {  box-sizing: border-box; text-align: center; }

#mainImg .tit {  box-sizing: border-box; height: 100px; padding-top: 14px; background-repeat: no-repeat; background-position: 50% 50%; background-size: cover; }

#mainImg .tit span {  display: block; line-height: 1.30435; padding-top: 38px; background: no-repeat 50% 0; background-size: 35px; color: #fff; font-weight: 700; font-size: 2.3rem; }

#mainImg .tit span.is_event, #mainImg .tit span.is_recommend {  background-image: url(../img/event/ico_mainimg01.png); }

#mainImg .tit span.is_event {  background-image: url(../img/event/ico_mainimg02.png); }

#mainImg .tit span[class*="area"] {  background-image: url(../img/area/ico_mainimg01.png); }

#mainImg .tit span.is_news {  background-image: url(../img/news/ico_mainimg01.png); }

#mainImg .tit span.is_gourmet {  background-image: url(../img/gourmet/ico_mainimg01.png); }

#mainImg .tit span.is_search {  background-image: url(../img/search/ico_mainimg01.png); }

#mainImg .tit span.is_about {  background-image: url(../img/about/ico_mainimg01.png); }

#mainImg .tit span.is_sightseen {  background-image: url(../img/sightseen/ico_mainimg01.png); }

#mainImg .tit span.is_contact {  background-image: url(../img/contact/ico_mainimg01.png); }

@media all and (min-width: 600px) {  #mainImg {  position: relative; height: 659px; }
  #mainImg .inner {  height: 191px; }
  #mainImg .tit {  height: 240px; padding-top: 49px; }
  #mainImg .tit span {  display: inline-block; line-height: 1.5; padding: 3px 0 3px 74px; background-position: 0 50%; background-size: 60px; font-size: 3.6rem; }
  #mainImg .bg {  position: absolute; bottom: 0; left: 0; width: 100%; height: 419px; background: url(../img/home/bg_mv02.png) no-repeat 50% 50%; background-size: cover; } }

/*!  #breadcrumb
================================================ */
@media all and (min-width: 600px) {  #breadcrumb {  overflow: hidden; margin-bottom: 45px; color: #8c8c8c; }
  #breadcrumb li {  display: inline-block; float: left; }
  #breadcrumb li + li {  position: relative; padding-left: 18px; }
  #breadcrumb li + li:before {  position: absolute; top: 0; left: 6px; color: #8c8c8c; content: ">"; }
  #breadcrumb li a {  display: block; color: #fd6978; text-decoration: underline; }
  #breadcrumb li a:hover {  text-decoration: none; } }

/*!  #sidebar
================================================ */
#sidebar .bnr {  margin-bottom: 30px; }

#sidebar .sideArchive {  margin: 0 0 26px; padding: 0 15px; }

@media screen and (min-width: 600px) {  #sidebar .sideArchive {  margin: 0 0 26px; padding: 0; } }

#sidebar .sideArchive .tit {  font-size: 1.6rem; line-height: 1; border-bottom: 1px solid #dcdcdc; margin-bottom: 16px; padding: 3px 0 14px 34px; background: url(../img/common/sidebar/ico_calendar01.png) 0 0 no-repeat; }

#sidebar .sideArchive .tit.area {  background: url(../img/common/sidebar/ico_area01.png) 0 0 no-repeat; }

@media screen and (min-width: 600px) {  #sidebar .sideArchive .tit {  font-size: 2rem; } }

#sidebar .sideArchive ul {  padding: 0 0 0 20px; }

#sidebar .sideArchive ul li {  margin: 0 0 15px; }

@media screen and (min-width: 600px) {  #sidebar .sideArchive ul li {  margin: 0 0 9px; } }

#sidebar .sideArchive ul li a {  display: block; position: relative; color: #fd6978; font-size: 1.4rem; }

#sidebar .sideArchive ul li a:before {  content: ""; position: relative; display: inline-block; width: 0; height: 0; margin: 0 10px 2px 0; border-style: solid; border-width: 4px 0 4px 5px; border-color: transparent transparent transparent #f36876; }

#sidebar .sideCalendar {  padding: 0 15px; }

@media screen and (min-width: 600px) {  #sidebar .sideCalendar {  padding: 0; } }

#sidebar .sideCalendar .year {  font-size: 2rem; line-height: 1; border-bottom: 1px solid #dcdcdc; margin-bottom: 20px; padding: 3px 0 14px 34px; background: url(../img/common/sidebar/ico_calendar01.png) 0 0 no-repeat; }

#sidebar .sideCalendar .list {  overflow: hidden; margin-bottom: 28px; }

#sidebar .sideCalendar .list .num {  width: 31.2%; margin-right: 8px; margin-bottom: 3px; float: left; }

@media screen and (min-width: 600px) {  #sidebar .sideCalendar .list .num {  width: 98px; margin-right: 3px; } }

#sidebar .sideCalendar .list .num:nth-of-type(3n) {  margin-right: 0; }

#sidebar .sideCalendar .list .num a {  display: block; line-height: 40px; background: #ffe7f0; color: #f5568b; font-size: 1.6rem; text-align: center; }

#sidebar .sideRecommend .tit {  font-size: 2rem; line-height: 1; border-bottom: 1px solid #dcdcdc; margin-bottom: 20px; padding: 3px 0 14px 34px; background: url(../img/common/ico_recom01.png) 0 0 no-repeat; background-size: 27px auto; }

#sidebar .sideRecommend .tit.sightseen {  background: url(../img/common/sidebar/ico_sightseen01.png) left top 4px no-repeat; background-size: 23px auto; }

#sidebar .sideRecommend .tit.gourmet {  background: url(../img/common/sidebar/ico_gourmet01.png) left top 4px no-repeat; background-size: 23px auto; }

#sidebar .popularList {  margin-bottom: 30px; }

#sidebar .popularList li {  border-bottom: 1px solid #dcdcdc; }

#sidebar .popularList li a {  display: block; padding-bottom: 15px; position: relative; min-height: 97px; padding-left: 135px; }

#sidebar .popularList li a .pic {  position: absolute; top: 0; left: 0; width: 120px; }

#sidebar .popularList li a .title {  font-size: 1.6rem; }

#sidebar .popularList li a .category {  display: inline-block; padding: 2px 12px; color: #9e4fbf; border-width: 1px; border-style: solid; border-color: #9e4fbf; margin-bottom: 10px; font-size: 1.2rem; }

#sidebar .popularList li a .category.gourmet {  color: #2198c4; border-color: #2198c4; }

#sidebar .popularList li + li a {  padding-top: 15px; min-height: 80px; }

#sidebar .popularList li + li a .pic {  top: 15px; }

/* #gFooter
================================================ */
/* #ftInfo
-------------------------------------- */
#ftInfo {  padding: 21px 0 16px; overflow: hidden; }

#ftInfo .logo {  float: left; width: 124px; }

#ftInfo .sns {  float: right; padding-top: 4px; overflow: hidden; }

#ftInfo .sns .item {  float: left; width: 30px; margin-left: 12px; }

@media screen and (min-width: 600px) {  #ftInfo {  padding: 28px 0 15px; }
  #ftInfo .logo {  width: 174px; }
  #ftInfo .sns {  padding-top: 7px; }
  #ftInfo .sns .item {  width: 26px; margin-left: 10px; } }

/* #ftSitemap
-------------------------------------- */
@media screen and (min-width: 600px) {  #ftSitemap {  margin-bottom: 45px; border-left: 1px solid #dcdcdc; overflow: hidden; }
  #ftSitemap .unit {  float: left; min-height: 125px; padding-left: 50px; border-right: 1px solid #dcdcdc; }
  #ftSitemap .unit .tit {  margin-bottom: 15px; padding-left: 26px; background: no-repeat -2px 50%; background-size: 21px; font-size: 1.5rem; font-weight: 500; line-height: 1.4; }
  #ftSitemap .unit .list {  font-size: 1.2rem; line-height: 1.83333; }
  #ftSitemap .unit .list a {  position: relative; display: block; padding-left: 13px; }
  #ftSitemap .unit .list a::before {  position: absolute; top: 50%; left: 0; width: 0; height: 0; content: ''; margin-top: -4px; border-style: solid; border-width: 4px 0 4px 4px; border-color: transparent transparent transparent #dcdcdc; }
  #ftSitemap .unit .list a:hover {  text-decoration: underline; }
  #ftSitemap .unit.category {  width: 259px; }
  #ftSitemap .unit.category .tit {  background-image: url(../img/common/ico_menu01_cat.png); }
  #ftSitemap .unit.category .list .item {  float: left; width: 105px; }
  #ftSitemap .unit.area {  width: 505px; }
  #ftSitemap .unit.area .tit {  background-image: url(../img/common/ico_menu01_area.png); }
  #ftSitemap .unit.area .list .item {  float: left; width: 159px; }
  #ftSitemap .unit.other {  width: 262px; }
  #ftSitemap .unit.other .list .item {  margin-bottom: 9px; font-size: 1.4rem; line-height: 1.5; }
  #ftSitemap .unit.other .list .item a {  padding-left: 15px; }
  #ftSitemap .unit.other .list .item a::before {  margin-top: -5px; border-width: 5px 0 5px 5px; border-left-color: #333; } }

/* #ftSub
-------------------------------------- */
#ftSub {  background: #4d4d4d; }

#ftSub .subNav {  padding: 21px 0 13px; overflow: hidden; }

#ftSub .subNav .item {  float: left; width: 50%; margin-bottom: 13px; box-sizing: border-box; font-size: 1.2rem; line-height: 1.5; }

#ftSub .subNav .item a {  position: relative; display: block; padding-left: 10px; color: #fff; }

#ftSub .subNav .item a::before {  position: absolute; top: 50%; left: 0; width: 0; height: 0; content: ''; margin-top: -4px; border-style: solid; border-width: 4px 0 4px 4px; border-color: transparent transparent transparent #fff; }

#ftSub .cr {  padding-bottom: 21px; text-align: center; font-size: 1.2rem; line-height: 1.5; }

#ftSub .cr small {  color: #8d8a8a; font-style: normal; }

@media screen and (min-width: 600px) {  #ftSub .inner {  padding: 26px 0 24px; overflow: hidden; }
  #ftSub .subNav {  float: left; padding: 0; }
  #ftSub .subNav .item {  width: auto; margin-bottom: 0; font-size: 1.3rem; line-height: 1.53846; }
  #ftSub .subNav .item + .item {  position: relative; padding-left: 33px; }
  #ftSub .subNav .item + .item::before {  position: absolute; top: 4px; left: 14px; content: ''; width: 1px; height: 13px; background: #706e6e; }
  #ftSub .subNav .item a {  padding-left: 0; }
  #ftSub .subNav .item a::before {  display: none; }
  #ftSub .subNav .item a:hover {  text-decoration: underline; }
  #ftSub .cr {  float: right; padding-bottom: 0; text-align: right; font-size: 1.2rem; line-height: 1.66667; } }

/* #pagetop
-------------------------------------- */
@media screen and (max-width: 599px) {  #pagetop {  display: none !important; } }

@media screen and (min-width: 600px) {  #pagetop {  display: none; position: fixed; margin: 0 20px 20px 0; right: 0; bottom: 0; z-index: 10; }
  #pagetop.is_stop {  position: absolute; } }

/*=============================================================
 05_helper
=============================================================*/
/*!  .js-name
================================================ */
/*=============================================================
 06_lib
=============================================================*/
