/*!  #breadcrumb
================================================ */
@media all and (min-width: 600px) { 
  #breadcrumb { 
    overflow: hidden;
    margin-bottom: 45px;
    color: #8c8c8c;
    li { 
      display: inline-block;
      float: left;
      + li { 
        position: relative;
        padding-left: 18px;
        &:before { 
          position: absolute;
          top: 0;
          left: 6px;
          color: #8c8c8c;
          content: ">";
        }
      }
      a { 
        display: block;
        color: #fd6978;
        text-decoration: underline;
        &:hover { 
          text-decoration: none;
        }
      }
    }
  }
}