/* #gHeader
================================================ */
#gHeader { 
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-width: 320px;
	background: #fff;
	z-index: 2000;
	@media screen and (min-width: 600px) { 
		min-width: 1180px;
		.inner { 
			width: 1180px;
			margin: 0 auto;
		}
		.btnSearch { 
			position: absolute;
			top: 0;
			right: 0;
			width: 75px;
			height: 75px;
			background: $clr_pink url(../img/common/ico_search01.png) no-repeat 50% 50%;
			background-size: 22px;
			cursor: pointer;
		}
	}
}
/* #hdInfo
-------------------------------------- */
#hdInfo { 
	position: relative;
	padding: 5px 0;
	height: 40px;
	background: #fff;
	.logo { 
		width: 133px;
		margin: 4px auto 0;
		img { 
			display: block;
		}
	}
	.btnMenu { 
		position: absolute;
		top: 0;
		right: 0;
		width: 50px;
		height: 50px;
		background: $clr_pink;
		cursor: pointer;
		.line { 
			position: absolute;
			top: 13px;
			left: 16px;
			width: 18px;
			height: 2px;
			background: #fff;
			@include trans;
			&:nth-child(1) {  top: 13px; }
			&:nth-child(2) {  top: 19px; }
			&:nth-child(3) {  top: 25px; }
		}
		.txt { 
			position: absolute;
			bottom: 6px;
			left: 0;
			width: 100%;
			color: #fff;
			font-size: 1.1rem;
			letter-spacing: .14em;
			text-align: center;
			line-height: (12/11);
		}
		&.is_open { 
			.line { 
				&:nth-child(1) {  top: 19px; transform: rotate(45deg);}
				&:nth-child(2) {  opacity: 0; }
				&:nth-child(3) {  top: 19px; transform: rotate(-45deg); }
			}
		}
	}
	@media screen and (min-width: 600px) { 
		float: left;
		height: auto;
		padding: 8px 0 11px;
		.logo { 
			width: 173px;
			height: 47px;
			margin: 6px 0 0;
		}
	}
}
/* #hdMenu
-------------------------------------- */
#hdMenu { 
	display: none;
	position: absolute;
	top: 50px;
	left: 0;
	width: 100%;
	min-width: 320px;
	padding-bottom: 28px;
	background: #f2f6f8;
	box-sizing: border-box;
	overflow-y: scroll;
	z-index: 2000;
	@media screen and (min-width: 600px) { 
		display: block;
		position: static;
		width: auto;
		float: right;
		padding-right: 160px;
		padding-bottom: 0;
		background: transparent;
		overflow-y: visible;
	}
	@media screen and (min-width: 1510px) { 
		padding-right: 0;
	}
}
/* #hdSearch
-------------------------------------- */
#hdSearch { 
	padding: 20px 15px;
	.inner { 
		position: relative;
		padding-right: 50px;
		.txt { 
			display: block;
			width: 100%;
			height: 45px;
			appearance: none;
			margin: 0;
			padding: 0 13px;
			border: 1px solid $clr_pink;
			border-radius: 4px;
			outline: none;
			background: #fff;
			box-shadow: none;
			box-sizing: border-box;
			font-size: 1.2rem;
			line-height: 1.5;
		}
		.btn { 
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			appearance: none;
			width: 45px;
			height: 45px;
			margin: 0;
			padding: 0;
			border: 0;
			outline: 0;
			box-shadow: none;
		}
	}
	@media screen and (max-width: 599px) { 
		.btn { 
			background: $clr_pink url(../img/common/ico_search01.png) no-repeat 50% 50%;
			background-size: 25px;
			text-indent: 100%;
			white-space: nowrap;
			overflow: hidden;
		}
	}
	@media screen and (min-width: 600px) { 
		display: none;
		position: absolute;
		top: 75px;
		left: 0;
		width: 100%;
		min-width: 1180px;
		padding: 28px 0 27px;
		background: #f2f6f8;
		.inner { 
			width: 450px;
			margin: 0 auto;
			padding-right: 160px;
			.txt { 
				height: 60px;
				padding: 0 15px;
				border-radius: 6px;
				font-size: 1.4rem;
			}
			.btn { 
				width: 150px;
				height: 60px;
				background: $clr_pink;
				box-sizing: border-box;
				color: #fff;
				font-size: 1.6rem;
				font-weight: 500;
				text-align: center;
				line-height: 1.5;
			}
		}
	}
}

/* #gNav
================================================ */
#gNav { 
	border-top: 1px solid #dcdcdc;
	.list { 
		margin-bottom: 30px;
		.item { 
			border-bottom: 1px solid #dcdcdc;
			> a { 
				position: relative;
				display: block;
				padding: 12px 15px 11px 35px;
				font-size: 1.4rem;
				font-weight: 500;
				line-height: 1.5;
				&::before { 
					position: absolute;
					top: 50%;
					left: 15px;
					width: 0;
					height: 0;
					content: '';
					margin-top: -4px;
					border-style: solid;
					border-width: 4px 0 4px 4px;
					border-color: transparent transparent transparent #333;
				}
				span { 
					color: $clr_pink;
				}
			}
			.parent { 
				position: relative;
				padding: 12px 15px 11px 37px;
				font-size: 1.5rem;
				font-weight: 500;
				line-height: 1.4;
				cursor: pointer;
				span { 
					color: $clr_pink;
				}
				.ico { 
					position: absolute;
					top: 50%;
					left: 13px;
					display: block;
					width: 17px;
					height: 17px;
					margin-top: -7px;
				}
				&::after { 
					position: absolute;
					top: 50%;
					right: 15px;
					width: 0;
					height: 0;
					content: '';
					margin-top: -2px;
					border-style: solid;
					border-width: 4px 4px 0 4px;
					border-color: $clr_pink transparent transparent;
				}
			}
			.content { 
				display: none;
			}
			.cat { 
				padding: 0 11px 7px;
				overflow: hidden;
				.child { 
					float: left;
					width: 50%;
					margin-bottom: 8px;
					padding: 0 4px;
					box-sizing: border-box;
					a { 
						display: block;
						height: 61px;
						padding: 12px 0 0;
						border: 1px solid;
						background: #fff;
						text-align: center;
						.ico { 
							display: block;
							margin-bottom: 1px;
							img { 
								display: block;
								width: 34px;
								margin: 0 auto;
							}
						}
						.txt { 
							font-size: 1.6rem;
							font-weight: 500;
						}
					}
					&.sightseen a {  color: $clr_purple; border-color: $clr_purple; }
					&.gourmet a {  color: $clr_sky; border-color: $clr_sky; }
					&.event a {  color: $clr_lime; border-color: $clr_lime; }
					&.experience a {  color: $clr_yellow; border-color: $clr_yellow; }
					&.souvenir a {  color: $clr_orange; border-color: $clr_orange; }
					&.hotel a {  color: $clr_cherry; border-color: $clr_cherry; }
					&.life a {  color: $clr_brown; border-color: $clr_brown; }
				}
			}
			.area { 
				padding: 18px 15px 4px;
				background: $clr_pink;
				overflow: hidden;
				.child { 
					float: left;
					width: 50%;
					margin-bottom: 12px;
					a { 
						position: relative;
						display: block;
						padding: 0 10px;
						color: #fff;
						font-size: 1.2rem;
						line-height: 1.5;
						&::before { 
							position: absolute;
							top: 50%;
							left: 0;
							width: 0;
							height: 0;
							content: '';
							margin-top: -4px;
							border-style: solid;
							border-width: 4px 0 4px 4px;
							border-color: transparent transparent transparent #fff;
						}
					}
				}
			}
		}
	}
	.contact { 
		width: 230px;
		margin: 0 auto 25px;
		a { 
			display: block;
			padding: 10px 15px 10px 82px;
			background: #4d4d4d url(../img/common/ico_contact01.png) no-repeat 54px 50%;
			background-size: 18px auto;
			color: #fff;
			font-weight: 500;
		}
	}
	.sns { 
		margin-bottom: 25px;
		text-align: center;
		letter-spacing: -.5em;
		.item { 
			display: inline-block;
			width: 30px;
			margin: 0 6px;
			letter-spacing: 0;
		}
	}
	.lang { 
		width: 180px;
		margin: 0 auto;
		overflow: hidden;
		.item { 
			float: left;
			width: 90px;
			a { 
				display: block;
				padding: 12px 0;
				background: #ffe7f0;
				color: $clr_pink;
				font-size: 1.4rem;
				font-weight: 500;
				text-align: center;
				line-height: 1.5;
			}
			&.current { 
				a { 
					background: $clr_pink;
					color: #fff;
				}
			}
		}
	}
	@media screen and (min-width: 600px) { 
		border-top: 0;
		.list { 
			float: left;
			margin-bottom: 0;
			margin-right: 12px;
			padding-top: 8px;
			.item { 
				float: left;
				border-bottom: 0;
				&:nth-child(1) {  width: 183px; }
				&:nth-child(2) {  width: 168px; }
				&:nth-child(3) {  width: 160px; }
				> a { 
					padding: 18px 0 17px 45px;
					font-size: 1.5rem;
					line-height: 1.4;
					&::before { 
						display: none;
					}
					.ico { 
						position: absolute;
						top: 50%;
						left: 15px;
						display: block;
						width: 20px;
						margin-top: -10px;
					}
				}
				.parent { 
					padding: 18px 0 17px 40px;
					.ico { 
						left: 15px;
						width: 20px;
						height: 20px;
						margin-top: -10px;
					}
					&::after { 
						position: absolute;
						top: auto;
						right: 50%;
						bottom: 5px;
						margin-top: 0;
						margin-right: -15px;
						border-color: #dcdcdc transparent transparent;
					}
				}
				.content { 
					position: absolute;
					top: 75px;
					left: 0;
					width: 100%;
					background: #f2f6f8;
					z-index: 2000;
				}
				.cat { 
					width: 1180px;
					margin: 0 auto;
					padding: 0;
					border-left: 1px solid #dcdcdc;
					box-sizing: border-box;
					&.temporary { 
						width:370px;
						.child { 
							width:50%;
						}
					}
					.child { 
						float: left;
						width: (100/7) + 0%;
						margin-bottom: 0;
						padding: 0;
						a { 
							display: block;
							height: 95px;
							padding: 30px 0 0;
							background: transparent;
							.ico { 
								margin-bottom: 5px;
								img { 
									width: 42px;
								}
							}
							.txt { 
								position: relative;
								font-size: 1.7rem;
								line-height: (24/17);
								&::before, &::after { 
									position: absolute;
									top: 63px;
									width: 2px;
									height: 24px;
									content: '';
									opacity: 0;
								}
								&::before { 
									left: -14px;
									transform: rotate(-30deg);
								}
								&::after { 
									right: -14px;
									transform: rotate(30deg);
								}
							}
						}
						&.sightseen a {  border: 1px solid #dcdcdc; border-width: 0 1px; }
						&.gourmet a, &.event a, &.experience a, &.souvenir a, &.hotel a, &.life a {  border: 1px solid #dcdcdc; border-width: 0 1px 0 0; }
						&.sightseen a { 
							border: 1px solid #dcdcdc;
							border-width: 0 1px;
							color: $clr_purple;
							.txt { 
								&::before, &::after {  background: $clr_purple; }
							}
						}
						&.gourmet a { 
							border: 1px solid #dcdcdc;
							border-width: 0 1px 0 0;
							color: $clr_sky;
							.txt { 
								&::before, &::after {  background: $clr_sky; }
							}
						}
						&.event a { 
							border: 1px solid #dcdcdc;
							border-width: 0 1px 0 0;
							color: $clr_lime;
							.txt { 
								&::before, &::after {  background: $clr_lime; }
							}
						}
						&.experience a { 
							border: 1px solid #dcdcdc;
							border-width: 0 1px 0 0;
							color: $clr_yellow;
							.txt { 
								&::before, &::after {  background: $clr_yellow; }
							}
						}
						&.souvenir a { 
							border: 1px solid #dcdcdc;
							border-width: 0 1px 0 0;
							color: $clr_orange;
							.txt { 
								&::before, &::after {  background: $clr_orange; }
							}
						}
						&.hotel a { 
							border: 1px solid #dcdcdc;
							border-width: 0 1px 0 0;
							color: $clr_cherry;
							.txt { 
								&::before, &::after {  background: $clr_cherry; }
							}
						}
						&.life a { 
							border: 1px solid #dcdcdc;
							border-width: 0 1px 0 0;
							color: $clr_brown;
							.txt { 
								&::before, &::after {  background: $clr_brown; }
							}
						}
						a:hover { 
							.txt { 
								animation: gnav-hover-txt .45s ease-in;
								&::before, &::after { 
									animation: gnav-hover-line .45s;
									top: 3px;
									opacity: 1;
								}
							}
						}
					}
				}
				.area { 
					width: 1180px;
					margin: 0 auto;
					padding: 27px 0 23px 613px;
					background: transparent;
					box-sizing: border-box;
					.child { 
						width: 160px;
						a { 
							padding: 0 12px;
							color: #333;
							&::before { 
								margin-top: -4px;
								border-color: transparent transparent transparent $clr_pink;
							}
							&:hover { 
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
		.sns { 
			float: left;
			margin-bottom: 0;
			padding-top: 25px;
			.item { 
				width: 25px;
				margin: 0 0 0 11px;
			}
		}
		.lang { 
			position: absolute;
			top: 0;
			right: 75px;
			width: 75px;
			height: 75px;
			margin: 0;
			.item { 
				float: none;
				width: 75px;
				a { 
					height: 75px;
					padding: 25px 0 0;
					font-size: 1.6rem;
					box-sizing: border-box;
				}
			}
		}
	}
}
@keyframes gnav-hover-line { 
	0% {  top: 63px; opacity: 0; }
	50% {  top: 63px; opacity: 1; }
	99.9375% {  top: 1px; }
	100% {  top: 3px; }
}
@keyframes gnav-hover-txt { 
	0% {  top: 0; }
	99.9375% {  top: -2px; }
	100% {  top: 0; }
}
