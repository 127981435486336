/* #gFooter
================================================ */
#gFooter { 
	@media screen and (min-width: 600px) { 
	}
}
/* #ftInfo
-------------------------------------- */
#ftInfo { 
	padding: 21px 0 16px;
	overflow: hidden;
	.logo { 
		float: left;
		width: 124px;
	}
	.sns { 
		float: right;
		padding-top: 4px;
		overflow: hidden;
		.item { 
			float: left;
			width: 30px;
			margin-left: 12px;
		}
	}
	@media screen and (min-width: 600px) { 
		padding: 28px 0 15px;
		.logo { 
			width: 174px;
		}
		.sns { 
			padding-top: 7px;
			.item { 
				width: 26px;
				margin-left: 10px;
			}
		}
	}
}
/* #ftSitemap
-------------------------------------- */
#ftSitemap { 
	@media screen and (min-width: 600px) { 
		margin-bottom: 45px;
		border-left: 1px solid #dcdcdc;
		overflow: hidden;
		.unit { 
			float: left;
			min-height: 125px;
			padding-left: 50px;
			border-right: 1px solid #dcdcdc;
			.tit { 
				margin-bottom: 15px;
				padding-left: 26px;
				background: no-repeat -2px 50%;
				background-size: 21px;
				font-size: 1.5rem;
				font-weight: 500;
				line-height: 1.4;
			}
			.list { 
				font-size: 1.2rem;
				line-height: (22/12);
				a { 
					position: relative;
					display: block;
					padding-left: 13px;
					&::before { 
						position: absolute;
						top: 50%;
						left: 0;
						width: 0;
						height: 0;
						content: '';
						margin-top: -4px;
						border-style: solid;
						border-width: 4px 0 4px 4px;
						border-color: transparent transparent transparent #dcdcdc;
					}
					&:hover { 
						text-decoration: underline;
					}
				}
			}
			&.category { 
				width: 259px;
				.tit {  background-image: url(../img/common/ico_menu01_cat.png); }
				.list { 
					.item { 
						float: left;
						width: 105px;
					}
				}
			}
			&.area { 
				width: 505px;
				.tit {  background-image: url(../img/common/ico_menu01_area.png); }
				.list { 
					.item { 
						float: left;
						width: 159px;
					}
				}
			}
			&.other { 
				width: 262px;
				.list { 
					.item { 
						margin-bottom: 9px;
						font-size: 1.4rem;
						line-height: 1.5;
						a { 
							padding-left: 15px;
							&::before { 
								margin-top: -5px;
								border-width: 5px 0 5px 5px;
								border-left-color: #333;
							}
						}
					}
				}
			}
		}
	}
}
/* #ftSub
-------------------------------------- */
#ftSub { 
	background: #4d4d4d;
	.subNav { 
		padding: 21px 0 13px;
		overflow: hidden;
		.item { 
			float: left;
			width: 50%;
			margin-bottom: 13px;
			box-sizing: border-box;
			font-size: 1.2rem;
			line-height: 1.5;
			a { 
				position: relative;
				display: block;
				padding-left: 10px;
				color: #fff;
				&::before { 
					position: absolute;
					top: 50%;
					left: 0;
					width: 0;
					height: 0;
					content: '';
					margin-top: -4px;
					border-style: solid;
					border-width: 4px 0 4px 4px;
					border-color: transparent transparent transparent #fff;
				}
			}
		}
	}
	.cr { 
		padding-bottom: 21px;
		text-align: center;
		font-size: 1.2rem;
		line-height: 1.5;
		small { 
			color: #8d8a8a;
			font-style: normal;
		}
	}
	@media screen and (min-width: 600px) { 
		.inner { 
			padding: 26px 0 24px;
			overflow: hidden;
		}
		.subNav { 
			float: left;
			padding: 0;
			.item { 
				width: auto;
				margin-bottom: 0;
				font-size: 1.3rem;
				line-height: (20/13);
				& + .item { 
					position: relative;
					padding-left: 33px;
					&::before { 
						position: absolute;
						top: 4px;
						left: 14px;
						content: '';
						width: 1px;
						height: 13px;
						background: #706e6e;
					}
				}
				a { 
					padding-left: 0;
					&::before { 
						display: none;
					}
					&:hover { 
						text-decoration: underline;
					}
				}
			}
		}
		.cr { 
			float: right;
			padding-bottom: 0;
			text-align: right;
			font-size: 1.2rem;
			line-height: (20/12);
		}
	}
}
/* #pagetop
-------------------------------------- */
#pagetop { 
	@media screen and (max-width: 599px) { 
		display: none !important;
	}
	@media screen and (min-width: 600px) { 
		display: none;
		position: fixed;
		margin: 0 20px 20px 0;
		right: 0;
		bottom: 0;
		z-index: 10;
		&.is_stop { 
			position: absolute;
		}
	}
}