@import "../01_setting/_var.scss";
@import "../01_setting/_mixin.scss";
/*!  02_base
================================================ */
html { 
	overflow: auto;
}
body { 
	min-width: 320px;
	color: #333;
	padding-top: 50px;
	font-size: 1.3rem;
	font-weight: 300;
	line-height: (20/13);
	font-family: "Noto Sans Japanese", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
	letter-spacing: .1em;
	overflow: hidden;
	@media screen and (min-width: 600px) { 
		min-width: 1180px;
		padding-top: 75px;
	}
}
div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video, input, select, textarea { 
	font-size: inherit;
	font-weight: inherit;
	font-family: inherit;
}
.mincho { 
	font-family: "Sawarabi Mincho", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	font-weight: 400;
}
a { 
	color:#333;
	text-decoration: none;
}
.alpha { 
	display: block;
	@media all and (min-width: 600px) { 
		@include trans;
		&:hover { 
			opacity: .8;
			text-decoration: none;
		}
	}
}
img { 
	width: 100%;
	height: auto;
}
.cf:after { 
	display: block;
	visibility: hidden;
	clear: both;
	content:"";
}
.viewPc {  display: none; }
@media all and (min-width: 600px) { 
	.viewSp {  display: none; }
	.viewPc {  display: block; }
}

/* layout
-------------------------------------------------*/
#wrapper { 
  position: relative;
}

@media all and (min-width: 600px) { 
  #container { 
    position: relative;
    z-index: 10;
    width: 1180px;
    margin: -504px auto 0;
    padding: 45px 0 90px;
    background: #fff;
    &:before { 
      position: absolute;
      top: 0;
      left: -80px;
      z-index: -1;
      width: 1340px;
      height: 100%;
      background: #fff;
      content: "";
    }
  }
	#home #container { 
		position: static;
		width:auto;
		margin:0;
		padding:0;
		background:transparent;
		&:before { 
			display: none;
		}
	}
}

@media all and (min-width: 600px) { 
  #contents { 
    float: left;
    width: 860px;
  }
}

@media all and (min-width: 600px) { 
  #sidebar { 
    float: right;
    width: 300px;
  }
}