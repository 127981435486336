@charset "utf-8";

$clr_pink: #fd6978;
$clr_pink_l: #ffe7f0;

$clr_purple: #9e4fbf;
$clr_sky: #2198c4;
$clr_lime: #71c72b;
$clr_yellow: #e6b33a;
$clr_orange: #ef7e3a;
$clr_cherry: #e54c80;
$clr_brown: #925848;

