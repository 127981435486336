/*!  #mainVisual HOME
================================================ */
#mainVisual { 
  background: #ffe7f0;
  .tit { 
    line-height: 1.4;
    padding: 14px 0;
    background: no-repeat 50% 50%;
    background-size: cover;
    color: #fff;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    letter-spacing: .14em;
  }
}

@media all and (min-width: 600px) { 
  #mainVisual { 
    position: relative;
    height: 659px;
    .tit { 
      height: 203px;
      padding: 37px 0 0;
      font-size: 2.6rem;
      span { 
        position: relative;
        &:before, &:after { 
          position: absolute;
          top: 2px;
          width: 4px;
          height: 35px;
          background: #fff;
          content: "";
        }
        &:before { 
          left: -28px;
          -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
        }
        &:after { 
          right: -28px;
          -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
        }
      }
    }
    .bg { 
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 419px;
      background: url(../img/home/bg_mv02.png) no-repeat 50% 50%;
      background-size: cover;
    }
    .img { 
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 1436px;
      margin-left: -730px;
      border: 12px solid #fff;
      border-width: 12px 12px 0;
      background: #fff;
    }
  }
}

/*!  #mainImg 下層
================================================ */

#mainImg { 
  box-sizing: border-box;
  text-align: center;
  .tit { 
    box-sizing: border-box;
    height: 100px;
    padding-top: 14px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    span { 
      display: block;
      line-height: 1.30435;
      padding-top: 38px;
      background: no-repeat 50% 0;
      background-size: 35px;
      color: #fff;
      font-weight: 700;
      font-size: 2.3rem;
      &.is_event, &.is_recommend { 
        background-image: url(../img/event/ico_mainimg01.png);
      }
		&.is_event{ 
        background-image: url(../img/event/ico_mainimg02.png);
		}
		&[class*="area"] { 
			background-image: url(../img/area/ico_mainimg01.png);
		}
		&.is_news{ 
			background-image: url(../img/news/ico_mainimg01.png);
		}
		&.is_gourmet{ 
			background-image: url(../img/gourmet/ico_mainimg01.png);
		}
		&.is_search{ 
			background-image: url(../img/search/ico_mainimg01.png);
		}
		&.is_about{ 
			background-image: url(../img/about/ico_mainimg01.png);
		}
		&.is_sightseen{ 
			background-image: url(../img/sightseen/ico_mainimg01.png);
		}
		&.is_contact{ 
			background-image: url(../img/contact/ico_mainimg01.png);
		}
    }
  }
}

@media all and (min-width: 600px) { 
  #mainImg { 
    position: relative;
    height: 659px;
    .inner { 
      height: 191px;
    }
    .tit { 
      height: 240px;
      padding-top: 49px;
      span { 
        display: inline-block;
        line-height: 1.5;
        padding: 3px 0 3px 74px;
        background-position: 0 50%;
        background-size: 60px;
        font-size: 3.6rem;
      }
    }
    .bg { 
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 419px;
      background: url(../img/home/bg_mv02.png) no-repeat 50% 50%;
      background-size: cover;
    }
  }
}