@mixin trans($prop: all, $time:.4s) { 
	transition: $prop $time ease-in-out;
}
@mixin scale($sx: 1.05) { 
	transform: scale($sx);
}
@mixin placeholder_color($color) { 
	&:placeholder-shown { 
		color: $color;
	}
	&::-webkit-input-placeholder { 
		color:$color;
	}
	&:-moz-placeholder { 
		color:$color;
		opacity: 1;
	}
	&::-moz-placeholder { 
		color:$color;
		opacity: 1;
	}
	&:-ms-input-placeholder { 
	color:$color;
	}
}